import { AxiosResponse } from 'axios';
import { apiV2Instance } from 'AppV2/configs/Axios/api_V2';
import { PostCreateUserRequest, PostCreateUserResponse } from './types';

export const postCreateUser = async (
  request: PostCreateUserRequest,
): Promise<AxiosResponse<PostCreateUserResponse>> => {
  try {
    const response = await apiV2Instance.post('/users/create', {
      userData: request,
    });
    console.log('[services][users][postCreateUser][RESPONSE]', response);

    return response;
  } catch (error: any) {
    const errorResponse = error?.response?.data || error;
    console.log('[services][users][postCreateUser][ERROR]', errorResponse);

    throw errorResponse;
  }
};
