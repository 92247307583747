/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { apiV2Instance } from 'AppV2/configs/Axios/api_V2';
import { GetOwnerDataRequest, GetOwnerDataResponse } from './types';

export const getOwnerData = async (
  request?: GetOwnerDataRequest,
): Promise<AxiosResponse<GetOwnerDataResponse>> => {
  try {
    const res = await apiV2Instance.get(
      `/users/ownerData${request?.userId ? `?userId=${request.userId}` : ''}`,
    );
    console.log('[services.Users.getOwnerData]', res);

    return res;
  } catch (error: any) {
    const errorResponse = error?.response?.data || error;
    console.error('[services.Users.getOwnerData]', errorResponse);

    throw errorResponse;
  }
};
