/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import {
  IGetUserProfileDataOwnerView,
  GetUserDataOwnerViewRequestProps,
} from './types';
import { apiV2Instance } from 'AppV2/configs/Axios/api_V2';

export const getUserDataOwnerView = async ({
  professionalId,
}: GetUserDataOwnerViewRequestProps): Promise<
  AxiosResponse<IGetUserProfileDataOwnerView>
> => {
  try {
    const res = await apiV2Instance.get(
      `/users/professionalData-ownerView/${professionalId}`,
    );
    console.log('[services.Users.GetUserDataOwnerView]', res);

    return res;
  } catch (error: any) {
    const errorResponse = error?.response.data || error;
    console.error('[services.Users.GetUserDataOwnerView]', errorResponse);
    throw errorResponse;
  }
};
