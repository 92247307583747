/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { GetUserDataAdminViewRequest, IGetUserProfileData } from './types';
import { apiV2Instance } from 'AppV2/configs/Axios/api_V2';

export const getUserDataAdminView = async ({
  professionalId,
}: GetUserDataAdminViewRequest): Promise<
  AxiosResponse<IGetUserProfileData>
> => {
  try {
    const res = await apiV2Instance.get(`/users?userId=${professionalId}`);
    console.log('[services.Users.getUserDataAdminView]', res);

    return res;
  } catch (error: any) {
    const errorResponse = error?.response.data || error;
    console.error('[services.Users.getUserDataAdminView]', errorResponse);
    throw errorResponse;
  }
};
