import styled, { CSSObject } from 'styled-components';
import { colors } from 'AppV2/styles/colors';

interface Props {
  styles: CSSObject;
}

export const ModalSecondaryHeights = {
  header: '52px',
  footerButtons: '98px',
};

export const Header = styled.header<Props>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  padding-bottom: 0px;
  border-radius: 12px 12px 0px 0px;
  background-color: ${colors.background200};

  h1 {
    margin: initial;
    color: ${colors.textBlack};
    font-family: Mulish;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    display: flex;
    align-items: center;
    gap: 6px;
    .icon-content {
      display: grid;
      place-items: center;
    }
  }

  .button-close-modal {
    width: 24px;
    height: 24px;
    transition: 0.3s;
    :hover {
      opacity: 0.72;
    }
  }

  @media screen and (max-width: 480px) {
    padding: 12px;
    h1 {
      font-size: 14px;
    }
  }

  ${({ styles }) => styles}
`;

export const ChildrenContainer = styled.div<Props>`
  padding: 24px;
  min-width: 483px;
  background-color: ${colors.background200};
  border-radius: 0px 0px 12px 12px;

  form > fieldset {
    overflow: auto;
    overflow-x: hidden;
    max-height: ${`calc(96vh - ${ModalSecondaryHeights.header} - ${ModalSecondaryHeights.footerButtons})`};
    max-height: ${`calc(96dvh - ${ModalSecondaryHeights.header} - ${ModalSecondaryHeights.footerButtons})`};
  }

  @media screen and (max-width: 480px) {
    min-width: 95vw;
    min-width: 95dvw;
    form > fieldset {
      ::-webkit-scrollbar {
        background-color: transparent !important;
        border: none !important;
        width: 0px !important;
      }
      ::-webkit-scrollbar-thumb {
        background-color: transparent !important;
        border: none !important;
        border-radius: 0px !important;
      }
    }
  }

  ${({ styles }) => styles}
`;

export const FooterButtons = styled.footer<Props>`
  display: flex;
  justify-content: space-between;
  gap: 12px;

  padding: 24px;
  border: 1px solid ${colors.faded};
  border-radius: 0px 0px 12px 12px;
  background-color: ${colors.white};

  @media screen and (max-width: 480px) {
    padding: 12px;
  }

  ${({ styles }) => styles}
`;
