/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { IGetUserProfileData } from './types';
import { apiV2Instance } from 'AppV2/configs/Axios/api_V2';

export const getUserData = async (): Promise<
  AxiosResponse<IGetUserProfileData>
> => {
  try {
    const res = await apiV2Instance.get('/users');
    console.log('[services.Users.GetUserData]', res);

    return res;
  } catch (error: any) {
    const errorResponse = error?.response.data || error;
    console.error('[services.Users.GetUserData]', errorResponse);

    throw errorResponse;
  }
};
