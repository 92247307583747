import axios, { AxiosResponse } from 'axios';
import { apiV2Instance } from 'AppV2/configs/Axios/api_V2';
import { PutUserServiceProps, PutUserServiceResponse } from './types';

export const putUserData = async ({
  newData,
  cancelToken,
}: PutUserServiceProps): Promise<AxiosResponse<PutUserServiceResponse>> => {
  try {
    const response = await apiV2Instance.put(
      '/users',
      {
        newData,
      },
      { cancelToken },
    );
    console.log('[services][PutUserData][RESPONSE]', response);

    return response;
  } catch (error: any) {
    const errorResponse = error?.response?.data || error;
    console.log('[services][PutUserData][ERROR]', errorResponse);

    if (axios.isCancel(error)) {
      throw new Error('isCancel');
    }

    throw errorResponse;
  }
};
