import { AxiosResponse } from 'axios';
import { apiV2Instance } from 'AppV2/configs/Axios/api_V2';
import { PostCustomClaimRequest, PostCustomClaimResponse } from './types';

export const postCustomClaim = async (
  request: PostCustomClaimRequest,
): Promise<AxiosResponse<PostCustomClaimResponse>> => {
  try {
    const res = await apiV2Instance.post('/auth', request);
    console.log('[Auth][postCustomClaim][RES]: ', res);

    return res;
  } catch (error: any) {
    const errorResponse = error?.response?.data || error;
    console.log('[Auth][postCustomClaim][ERROR]: ', errorResponse);

    throw errorResponse;
  }
};
