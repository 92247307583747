import { putUserProfilePicture } from './PutUserProfilePicture/index';
import { getUserData } from './GetUserData';
import { putUserData } from './PutUserData';
import { getUserDataOwnerView } from './GetUserDataOwnerView';
import { getUserDataAdminView } from './GetUserDataAdminView';
import { postCreateUser } from './PostCreateUser';
import { putProfileRegister } from './PutProfileRegister';
import { getOwnerData } from './GetOwnerData ';

export const userServices = {
  postCreateUser,
  putProfileRegister,
  putUserData,
  putUserProfilePicture,
  getUserData,
  getUserDataOwnerView,
  getUserDataAdminView,
  getOwnerData,
};
