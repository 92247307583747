export const OWNER_ROUTES = [
  '/squadOwner-profile',
  '/squadOwner-register',
  '/dashboard',
  '/extract-payments',
  '/workspace',
  '/my-projects',
  '/my-freelancers',
  '/onboarding',
  '/project-brief-selection',
  '/project-brief-onboarding',
  '/project-brief-forms',
  '/project-brief-thanks-page',
  '/support-squadOwner-view',
  '/task-assignments',
  '/forbidden',
  '/squadMember-viewProfile',
  '/project-brief-campaign-thanks-page',
  '/new-journey',
  '/squadmember-profile/hiring-process-view',
  '/matchmaking',
  '/tests-resumes',
  '/persona',
  '/user-recommendations',
  '/ocai-complementary',
];

export const MEMBER_ROUTES = [
  '/send-email-reset-password',
  '/confirm-email',
  '/squadMember-fill-profile',
  '/squadMember-register',
  '/squadMember-profile',
  '/opportunities',
  '/tests-selection',
  '/support-squadMember-view',
  '/quiz-personality',
  '/tests-softskills-questions',
  '/tests-organizational-culture-questions',
  '/tests-personality-results',
  '/tests-softskills-results',
  '/tests-organizational-culture-results',
  '/tests-personality-completed',
  '/tests-softskills-completed',
  '/tests-organizational-culture-completed',
  '/task-assignments',
  '/tests-resumes',
  '/forbidden',
  '/applications',
];

export const APP_V2_PATHS = [
  '/onboarding',
  '/my-onboardings',
  '/extract-payments',
  '/my-freelancers',
  '/my-projects',
  '/dashboard',
  '/workspace',
  '/task-assignments',
  '/squadMember-profile/campaign',
  '/new-journey',
  '/tests-organizational-culture',
  '/credits-and-payments',
  '/ocai',
  '/user-tests-profile-intensifiers',
  '/matchmaking',
  '/jobs/details-view',
  '/quiz-moving-motivators',
  '/shared-moving-motivators',
  '/tests-selection',
  '/squadMember-profile',
  '/squadMember-viewProfile',
  '/squadmember-profile/hiring-process-view',
  '/quiz-personality',
  '/tests-resumes',
  '/cms-match-rating',
  '/persona',
  '/profile-export',
  '/applications',
  '/recommender-evaluation',
  '/user-recommendations',
  '/assessments',
  '/ocai-organizational-culture-tool',
  '/ocai-complementary',
  '/company-ocai-complementary',
  '/open',
  '/assessments-candidate',
  '/cms/persona',
  '/company',
];

export const URL_PATHS_WITHOUT_NAVBAR = [
  '/squadMember-fill-profile',
  '/squadMember-register',
  '/squadOwner-register',
  '/freelancer-view',
  '/project-view',
  '/project-brief-onboarding',
  '/project-brief-thanks-page',
  '/project-brief-campaign-thanks-page',
  '/shared-personality-results',
  '/shared-softskills-results',
  '/shared-organizational-culture-results',
  '/send-email-reset-password',
  '/confirm-email',
  '/email-checked',
  '/upsell-thankyoupage',
  '/cms-selection',
  '/login',
  '/signup',
  '/matchmaking-signup',
  '/assessment-signup',
  '/sales-signup',
  '/reset-password',
  '/upsell-thankyoupage',
  '/service',
  '/squadMember-profile/campaign',
  '/kanban-view',
  '/new-journey',
  '/quiz-moving-motivators',
  '/shared-moving-motivators',
  '/matchmaking/thankyoupage',
  '/matchmaking/invite-view',
  '/matchmaking/create-persona-view',
  '/workspace/invite-view',
  '/persona',
  '/profile-export',
  '/recommender-evaluation',
];

export const URL_PATHS_WITHOUT_HEADER = [
  '/squadMember-fill-profile',
  '/squadMember-register',
  '/squadOwner-register',
  '/project-brief-onboarding',
  '/project-brief-thanks-page',
  '/project-brief-campaign-thanks-page',
  '/shared-personality-results',
  '/shared-softskills-results',
  '/shared-organizational-culture-results',
  '/shared-moving-motivators',
  '/confirm-email',
  '/email-checked',
  '/send-email-reset-password',
  '/login',
  '/signup',
  '/matchmaking-signup',
  '/assessment-signup',
  '/sales-signup',
  '/service',
  '/new-journey',
  '/profile-export',
];

export const URL_PATHS_WITHOUT_MOBILE = [
  '/quiz-personality',
  '/task-assignments',
  '/matchmaking',
  '/cms-match-rating',
  '/profile-export',
];

export const LOGIN_PAGES = [
  '/',
  '/login',
  '/signup',
  '/signup-admin',
  '/sales-signup',
  '/sales-signup/',
];

export const ASSESSMENT_MEMBER_PATHS = [
  '/squadMember-profile',
  '/tests-selection',
  '/support-squadMember-view',
];

export const getOpenPages = routerParam => [
  '/sales-signup/',
  '/matchmaking-signup',
  '/assessment-signup',
  '/project-brief-onboarding',
  '/project-brief-selection',
  '/project-brief-forms',
  '/service',
  '/project-brief-blocked-page',
  '/send-email-reset-password',
  `/shared-personality-results${!!routerParam && `/${routerParam}`}`,
  `/shared-organizational-culture-results${!!routerParam && `/${routerParam}`}`,
  `/shared-softskills-results${!!routerParam && `/${routerParam}`}`,
  `/project-brief-view${!!routerParam && `/${routerParam}`}`,
  '/top-talent/',
  '/shared-moving-motivators',
  '/jobs',
  '/matchmaking/invite-view',
  '/workspace/invite-view',
  '/assessments/invite-view',
  '/recommender-evaluation',
  '/open',
  '/assessments-candidate',
  '/company',
  '/cms/persona',
];
