import { AxiosResponse } from 'axios';
import { apiV2Instance } from 'AppV2/configs/Axios/api_V2';
import { PutProfileRegisterRequest, PutProfileRegisterResponse } from './types';

export const putProfileRegister = async (
  request: PutProfileRegisterRequest,
): Promise<AxiosResponse<PutProfileRegisterResponse>> => {
  try {
    const response = await apiV2Instance.put('/users/profileRegister', {
      updateObj: request,
    });
    console.log('[services][Users][PutProfileRegister][RESPONSE]', response);

    return response;
  } catch (error: any) {
    const errorResponse = error?.response?.data || error;
    console.log('[services][Users][PutProfileRegister][ERROR]', errorResponse);

    throw errorResponse;
  }
};
