import { WorkspacePathsEnum } from 'AppV2/assets/allWorkspacePaths';
import { UserRoleType } from 'AppV2/interfaces/userRole';
import firebase from 'firebase/app';
import { Dispatch, MutableRefObject, SetStateAction } from 'react';

export const userAuthCacheMinutes = 50;
export const UserAuthTokenQueryCacheKey = 'userAuthToken';
export const ShouldShowPostProjectPopUpStorageKey =
  'ShouldShowPostProjectPopUp';

export type User = firebase.User | null;
export type UserCredential = firebase.auth.UserCredential;
export type Workspace = string;
export interface ParamProps {
  campaign: 'campaign';
}

export type CustomClaim = {
  userRole: UserRoleType;
  navigationStage: string;
  userIsAvailable: boolean;
  headerSubtitle: string;
  workspaceId?: Workspace;
  [key: string]: any;
};
export interface CurrentUser extends User {
  claims: CustomClaim;
}
export type getSalesSignIn = boolean;

export type VerifyUSerEmail = {
  status: boolean;
  message: string;
};

export type AxiosDefaultHeaders = {
  userRole: UserRoleType;
  workspaceId: string;
  token: string;
};

export type UpdateCurrentUserProps = {
  key?: string;
  value?: any;
  keys?: string[];
  values?: any[];
};

export type AuthContextValues = {
  isLoadingRequest: boolean;
  setIsLoadingRequest: Dispatch<SetStateAction<boolean>>;
  isLoginError: boolean;
  setIsLoginError: Dispatch<SetStateAction<boolean>>;
  loginErrorMessage: MutableRefObject<string>;
  defaultAxiosHeaders: AxiosDefaultHeaders;
  isAuth: boolean;
  isNewOwnerLoggedInProjectBriefFlow: boolean;
  isAppV2Paths: boolean;
  shouldShowHeader: boolean;
  shouldShowNavBar: boolean;
  workspaceSelectedForbiddens: WorkspacePathsEnum[];
  setWorkspaceSelectedForbiddens: Dispatch<
    SetStateAction<WorkspacePathsEnum[]>
  >;
  getCurrentUserPhoto: () => string;
  getRefreshToken: () => Promise<string>;
  currentUser: CurrentUser;
  userCustomClaim?: CustomClaim;
  setUserCustomClaim: Dispatch<SetStateAction<undefined | CustomClaim>>;
  returnLoginMessage: null | UserCredential;
  returnSingUpMessage: null | UserCredential;
  isFirstSocialLogin: boolean;
  setCustomClaimFirstStage(accountType: string): Promise<void>;
  setReturnSignUpMessage: Dispatch<SetStateAction<null | UserCredential>>;
  setIsAdminSignUpOk: Dispatch<SetStateAction<boolean>>;
  isAdminSignUpOk: boolean;
  setReturnLoginMessage: Dispatch<SetStateAction<null | UserCredential>>;
  emailSignup(
    email: string,
    password: string,
    accountType: string,
    adminData?: unknown,
  ): Promise<void>;
  emailLogin(email: string, password: string): Promise<boolean>;
  googleSignup(accountType: string): Promise<void>;
  googleLogin(): Promise<void>;
  sendVerifyEmail(): Promise<void>;
  getUserEmail(): string;
  userEmailIsVerified(): string | boolean;
  sendPasswordResetEmail(email: string): Promise<void>;
  getIdToken(): Promise<string>;
  getCurrentUser(): Promise<User>;
  logout(): Promise<void>;
  getUserDisplayName(): string;
  updateDisplayName: (displayName: string) => Promise<void>;
  firebaseResetPassword(newPassword: string): Promise<void>;
  checkPagePermission(
    urlToRedirect: string,
    userType: string,
    urlPath: string,
    navigationStage: string,
  ): Promise<void | string>;
  getCustomClaim(claim?: string): Promise<any>;
  userIdToken: string | null;
  isSuccessfulResetPassword: string | boolean;
  setIsSuccessfulResetPassword: Dispatch<SetStateAction<string | boolean>>;
  isSuccessfulEmailChecked: boolean;
  verifyUSerEmail(actionCode: string): Promise<VerifyUSerEmail>;
  resetPassword(actionCode: string, newPassword: string): Promise<void>;
  isSuccessfulResetPasswordSent: boolean;
  setIsSuccessfulResetPasswordSent: Dispatch<SetStateAction<boolean>>;
  updateCurrentUser: ({ ...props }: UpdateCurrentUserProps) => void;
};
