import axios, { AxiosResponse } from 'axios';
import { apiV2Instance } from 'AppV2/configs/Axios/api_V2';
import { PutUserProfilePictureProps } from './types';

export const putUserProfilePicture = async (
  request: PutUserProfilePictureProps,
): Promise<AxiosResponse<Response>> => {
  try {
    const response = await apiV2Instance.post(
      '/users/photo',
      {
        image: request.image,
      },
      { cancelToken: request.cancelToken },
    );
    console.log('[services][PutUserProfilePicture][RESPONSE]', response);

    return response;
  } catch (error: any) {
    const errorResponse = error?.response?.data || error;
    console.log('[services][PutUserProfilePicture][ERROR]', errorResponse);

    if (axios.isCancel(error)) {
      throw new Error('isCancel');
    }

    throw errorResponse;
  }
};
