import { useEffect, useLayoutEffect, useMemo } from 'react';
import { TEMPLATES_KEY_CACHE_QUERY, TemplatesKeysEnum } from './types';
import { useFavIcons } from './favIcons';
import { useLogos } from './logos';
import { useLottieLoadings } from './lotties';
import { queryClient } from 'AppV2/configs/QueryClient';
import { colors } from 'AppV2/styles/colors';

export const useTemplatesConfig = () => {
  // HOOKS
  const favIcons = useFavIcons();
  const logos = useLogos();
  const lottieLoadings = useLottieLoadings();

  // VALUES
  const locationHref = String(window.location.href).toLowerCase();

  const templateKey = useMemo(() => {
    const isHunterHero =
      locationHref.includes(TemplatesKeysEnum.HUNTERS_99) ||
      locationHref.includes('99hunters');

    if (isHunterHero) {
      queryClient.setQueryData(
        TEMPLATES_KEY_CACHE_QUERY,
        () => TemplatesKeysEnum.HUNTERS_99,
      );
      sessionStorage.setItem(
        TEMPLATES_KEY_CACHE_QUERY,
        TemplatesKeysEnum.HUNTERS_99,
      );

      return TemplatesKeysEnum.HUNTERS_99;
    } else {
      queryClient.setQueryData(
        TEMPLATES_KEY_CACHE_QUERY,
        () => TemplatesKeysEnum.GROWYX,
      );
      sessionStorage.setItem(
        TEMPLATES_KEY_CACHE_QUERY,
        TemplatesKeysEnum.GROWYX,
      );

      return TemplatesKeysEnum.GROWYX;
    }
  }, [locationHref]);

  const headTemplateData = useMemo(
    () => ({
      [TemplatesKeysEnum.GROWYX]: {
        title: 'Growyx - Hire and manage top talent',
        description:
          'Contrate e gerencie os melhores talentos de forma rápida, segura e transparente',
        favIcon: favIcons.growyx.favIcon,
        favIconAppleTouch: favIcons.growyx.appleTouch,
      },
      [TemplatesKeysEnum.HUNTERS_99]: {
        title: 'HunterHero',
        description:
          'Contrate os melhores talentos com eficiência e agilidade. A 99Hunters é sua parceira global em recrutamento ativo, conectando sua empresa com talentos excepcionais. Descubra como podemos ajudar você a preencher vagas com precisão e rapidez.',
        favIcon: favIcons[TemplatesKeysEnum.HUNTERS_99].favIcon,
        favIconAppleTouch: favIcons[TemplatesKeysEnum.HUNTERS_99].appleTouch,
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // EFFECTS
  useLayoutEffect(() => {
    const storageTemplateKey = queryClient.getQueryData<TemplatesKeysEnum>(
      TEMPLATES_KEY_CACHE_QUERY,
    );

    if (storageTemplateKey !== templateKey) {
      sessionStorage.setItem(TEMPLATES_KEY_CACHE_QUERY, templateKey);
      queryClient.setQueryData(TEMPLATES_KEY_CACHE_QUERY, () => templateKey);
    }
  }, [templateKey]);

  useEffect(() => {
    if (colors.templateKey !== templateKey) {
      sessionStorage.setItem(TEMPLATES_KEY_CACHE_QUERY, templateKey);
      location.reload();
    }
  }, [templateKey]);

  // RETURN
  return {
    templateKey: templateKey,
    logos: logos,
    lottieLoading: lottieLoadings[templateKey],
    head: headTemplateData[templateKey],
  };
};
